import axios from '../utils/request'

export function get_all_VenueActivity(params){
    return axios({
        url:'/get_all_VenueActivity',
        method:'get',
        params
    })
}

export function add_venueActivity(data){
    return axios({
        url:'/add_venueActivity',
        method:'post',
        data
    })
}

export function edit_venue_activity(data){
    return axios({
        url:'/edit_venue_activity',
        method:'post',
        data
    })
}

export function del_venue_activity(data){
    return axios({
        url:'/del_venue_activity',
        method:'post',
        data
    })
}
