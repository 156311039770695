<template>
  <div class="index">
    <div class="header">
      <h2 style="color:var( --td-text-color-primary)">活动管理</h2>
      <t-button theme="primary" @click="add_toast=true,venue_toast_title='添加活动',this.initForm()">
        <template #icon>
          <t-icon name="add"></t-icon>
        </template>
        添加
      </t-button>
    </div>
    <div class="table">
      <t-table :fixed-rows="[0,0]" :loading="table_loading" :data="data" :columns="columns" row-key="id" table-layout="auto" style="height: calc(100% - 90px);border-bottom: 1px solid var(--td-border-level-2-color);overflow-y: auto" headerAffixedTop>
        <template #img="{ col, row }">
          <el-image
              style="width: 70px;height: 70px;"
              :src="row.img.split('|')[0]"
              :preview-src-list="row.img.split('|')"
              :initial-index="4"
              fit="cover"
          />
        </template>
        <template #time="{row}">
          {{`${row.start_time}至${row.end_time}`}}
        </template>
        <template #opertion="{row}">
          <t-popconfirm content="确认删除该展馆活动？" theme="danger" @confirm="del_venue(row)">
            <t-button variant="text" theme="danger"> 删除 </t-button>
          </t-popconfirm>
          <t-button variant="text" theme="success" @click="edit(row)"> 编辑 </t-button>
        </template>
      </t-table>
      <div class="pages">
        <t-pagination
            v-model="pages"
            :pageSize="limit"
            :total="total"
            show-jumper
            style="width: 100%;"
            @currentChange="pageChange"
            @page-size-change="pageSizeChange"
        />
      </div>
    </div>
    <t-dialog :header="venue_toast_title" placement="center" :visible="add_toast" @confirm="up" @close="add_toast=false" width="700px">
      <template #body>
        <t-form :data="formData" :rules="rules" ref="form">
          <t-form-item label="展馆名" name="venue_id">
            <t-select @change="choice_venue" v-model="formData.venue_id" :options="name_list" placeholder="请选择展馆"/>
          </t-form-item>
          <t-form-item label="活动时间" name="start_time">
            <t-date-picker
                mode="date"
                range
                clearable
                v-model="choice_time"
                format="YYYY-MM-DD HH:mm:ss"
                enable-time-picker
                style="width: 100%"
                @change="timeChange"
            />
          </t-form-item>
          <t-form-item label="活动标题" name="title">
            <t-input placeholder="请输入内容" v-model="formData.title" />
          </t-form-item>
          <t-form-item label="活动内容" name="con">
            <t-textarea placeholder="请输入内容"  v-model="formData.con"/>
          </t-form-item>
          <t-form-item label="活动图片" name="img">
            <div style="width:100%;display: flex;justify-content: flex-start;align-content: flex-start;flex-direction: column">
              <div style="display:flex;margin-bottom: 20px">
                <el-upload
                    action="https://gqt.yltest.top/file"
                    :http-request="up_img"
                    list-type="picture-card"
                    :on-success="upImg"
                    :on-remove="del_img"
                    :file-list="file1">
                  <icon name="add" size="50"></icon>
                </el-upload>
              </div>
              <el-progress :percentage="progress"></el-progress>
            </div>
          </t-form-item>
        </t-form>
      </template>
    </t-dialog>
    <t-dialog @close="choice_poin_toast=false" @confirm="choice_poin" header="查找地点坐标" placement="center" :visible="choice_poin_toast" width="60%" height="600px">
      <template #body>
        <div style="width: 300px;margin-bottom: 20px;display: flex">
          <t-input placeholder="请输入内容" v-model="address" />
          <t-button :loading="search_address_loading" theme="primary" style="margin-left: 10px;" @click="search_address">搜索地点</t-button>
        </div>
        <div id="container"></div>
      </template>
    </t-dialog>
  </div>
</template>
<script>
import { shallowRef } from '@vue/reactivity'
import {Icon} from 'tdesign-icons-vue-next'
import AMapLoader from '@amap/amap-jsapi-loader';
import {MessagePlugin} from "tdesign-vue-next";
import { ElUpload,ElImage,ElProgress } from 'element-plus'
import 'element-plus/dist/index.css'
import {add_venueActivity, del_venue_activity, edit_venue_activity, get_all_VenueActivity} from "../../api/activity";
import {get_all_venue_name} from "../../api/comment";
import {add_venue} from "../../api/venue";
import {get_progress, up} from "../../utils/tx_cos_up";
export default {
  components:{
    Icon,
    ElUpload,ElImage,
    ElProgress
  },
  setup(){
    const map = shallowRef(null);
    return{
      map,
    }
  },
  data(){
    return {
      search_address_loading:false,
      current:1,
      data:[],
      pages:1,
      limit:10,
      total:0,
      table_loading:false,
      columns:[
        {
          colKey: 'name',
          title: '展馆',
        },
        {
          colKey: 'title',
          title: '活动标题',
          className: 'row_tr',
        },
        {
          colKey: 'time',
          title:'活动时间',
          width: '400px'
        },
        {
          colKey: 'con',
          title:'活动内容',
          ellipsis:true
        },
        {
          colKey: 'img',
          title:'图片',
        },
        {
          colKey: 'opertion',
          title:'操作',
          align:'center',
          width:'250px',
          className: 'row_tr',
        }
      ],
      add_toast:false,
      formData:{
        venue_id:'',
        title:'',
        start_time:'',
        end_time:"",
        con:"",
        img:''
      },
      choice_time:[],
      choice_poin_toast:false,
      address:'',
      markers:[],
      file1:[],
      rules:{
        venue_id:[{ required: true}],
        start_time:[{ required: true}],
        title:[{ required: true}],
        con:[{ required: true}],
        img:[{ required: true}]
      },
      venue_toast_title:'添加活动',
      name_list:[],
      progress:0,
      progress_time:null
    }
  },
  computed:{
    time(){
      return (work_time)=>{
        let start = JSON.parse(work_time).start
        let end = JSON.parse(work_time).end
        return `${start}至${end}`
      }
    }
  },
  mounted() {
    this.get_list()
    //获取展馆列表
    this.get_venue_list()
  },
  methods:{
    //上传图片
    async up_img(e){
      let x = 0
      setInterval(()=>{
        this.progress = Number(get_progress())
        if(this.progress===100 || x===1){
          this.progress_time = null
          clearInterval(this.progress_time)
        }
      })
      let res = await up((e.file.uid.toString()+e.file.name),e.file)
      x = 1
      if(this.formData.img===''){
        this.formData.img += 'https://'+res
      }else {
        this.formData.img += '|'+'https://'+res
      }
      console.log(this.formData.img)
    },
    async get_venue_list(){
      let {data} = await get_all_venue_name()
      data.forEach(item=>{
        this.name_list.push({
          label:item.name,
          value:item.id
        })
      })
    },
    choice_venue(e){
      this.formData.venue_id = e
    },
    //初始化地图
    initMap(){
      this.choice_poin_toast = true
      AMapLoader.load({
        key:"1c9d6f1f79687710334ee12f44b89411",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins:[''],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap)=>{
        this.map = new AMap.Map("container",{  //设置地图容器id
          viewMode:"3D",    //是否为3D地图模式
          zoom:5,           //初始化地图级别
          center:[105.602725,37.076636], //初始化地图中心点位置
        });
        this.map.on('click',(e)=>this.user_set_poin(e))
      }).catch(e=>{
        console.log(e);
      })
    },
    async get_list(){
      this.table_loading = true
      let res = await get_all_VenueActivity({pages:this.pages,limit:this.limit})
      this.data = [...res.data,...this.data]
      this.table_loading = false
      console.log(this.data)
      this.total = res.count
    },
    pageChange(e){
      this.pages = e
      this.data = []
      this.get_list()
    },
    pageSizeChange(e){
      this.limit = e
      this.data = []
      this.get_list()
    },
    timeChange(e){
      this.formData.start_time = e[0]
      this.formData.end_time = e[1]
      console.log(this.formData)
    },
    //设置坐标
    choice_poin(){
      this.choice_poin_toast = false
      console.log('sdafasdf')
    },
    async search_address(){
      if(this.address===''){
        MessagePlugin.warning({content:'请输入详细地址'})
        return
      }
      this.search_address_loading = true
      let data = await get_address_poin({address:this.address})
      this.search_address_loading = false
      this.set_poin(data.data.geocodes[0].location.split(','))
      console.log(data)
    },
    set_poin(poin){
      this.removeMarkers()
      this.formData.address_poin.lat = poin[0]
      this.formData.address_poin.lng = poin[1]
      var marker = new AMap.Marker({
        position: new AMap.LngLat(poin[0], poin[1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: this.address
      });
      this.markers.push(marker)
      this.map.add(marker);
      this.map.setCenter(poin);
      this.map.setZoom(16);
    },
    //用户手动设置定位点
    user_set_poin(e){
      this.removeMarkers()
      this.formData.address_poin.lat = e.lnglat.lng
      this.formData.address_poin.lng = e.lnglat.lat
      var marker = new AMap.Marker({
        position: new AMap.LngLat(e.lnglat.lng,e.lnglat.lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: this.address
      });
      this.markers.push(marker)
      this.map.add(marker);
      this.map.setCenter([e.lnglat.lng,e.lnglat.lat]);
    },
    removeMarkers(){
      for(let i of this.markers){
        this.map.remove(i)
      }
      this.markers = []
    },
    upImg(e){
      console.log(e)
      if(this.formData.img===''){
        this.formData.img += e.data
      }else {
        this.formData.img += '|'+e.data
      }
      console.log(this.formData.img)
    },
    del_img(e){
      console.log(e)

      let img = ''
      this.formData.img.split('|').forEach((item,index)=>{
        if(item !== e.url){
          if(img===''){
            img += item
          }else{
            img += '|'+item
          }
        }
      })
      this.formData.img = img
      console.log(this.file1)
      console.log(this.formData.img)
    },
    initForm(){
      this.formData = {
        venue_id:'',
        title:'',
        start_time:'',
        end_time:"",
        con:"",
        img:''
      }
      this.choice_time = []
      this.file1 = []
      this.$refs.form.clearValidate()
    },
    async up(){
      if(await this.$refs.form.validate()!==true){
        MessagePlugin.warning({content:'请填写完整'})
        return
      }

      if(this.venue_toast_title === '编辑活动'){
        this.edit_up()
        return
      }
      console.log(this.formData)
      this.$loading(true);
      let data = await add_venueActivity(this.formData)
      this.$loading(false)
      if(data.code===100){
        MessagePlugin.success({content:"添加成功！"})
        this.add_toast = false
      }else {
        MessagePlugin.error({content:"添加失败！"})
      }
      this.data = []
      this.get_list()
      console.log(data)
    },
    async edit_up(){
      delete this.formData.address_poin
      delete this.formData.address_str
      delete this.formData.name

      this.$loading(true);
      let data = await edit_venue_activity(this.formData)
      this.$loading(false)
      if(data.code===100){
        MessagePlugin.success({content:"编辑成功！"})
        this.add_toast = false
      }else {
        MessagePlugin.error({content:"编辑失败！"})
      }
      this.data = []
      this.get_list()
      console.log(data)
    },
    //  删除展馆
    async del_venue({id}){
      let {code,msg} = await del_venue_activity({id})
      if(code===100) MessagePlugin.success(msg)
      else MessagePlugin.error(msg)
      this.data = []
      this.get_list()
    },
    //  编辑活动
    async edit(row){
      this.venue_toast_title = '编辑活动'
      this.formData = row
      this.file1=[]
      this.formData.img.split('|').map(item=>{
        this.file1.push({
          lastModified:123045,
          name:'sdaf',
          percent:100,
          raw:{},
          response:{},
          size:10,
          status:'success',
          type:'image',
          url:item
        })
      })
      this.choice_time=[row.start_time,row.end_time]
      this.add_toast = true
    }
  }
}
</script>

<style scoped>
#container{
  width: 100%;
  height: 600px;
}
.table{
  height: calc(100% - 90px);
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;
}
.index{
  width: 100%;
  height: 100%;
  background-color: var(--td-bg-color-container);
}
.row_tr{
  height: 40px;
}
.header>h2{
  font-size: 24px;
  color: #383838;
  letter-spacing: 1px;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 8px 12px;
  box-sizing: border-box;
}
.pages{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  padding: 0 8px;
  box-sizing: border-box;
}
</style>

<style>
.t-table th:not([align]), .t-table td:not([align]){
  height: 40px;
}
.index .t-table__body>tr{
  height: 40px;
}
.index .t-time-picker{
  width: 100%;
}
.index .t-pagination__total{
  text-align: left;
}
.index .t-button--variant-base.t-button--theme-primary{
  background-color: #42b983;
  border-color: #42b983;
}
.index .t-button--variant-base.t-button--theme-primary>div>div{
  background-color: #24b07d !important;
}
.index>.t-table__header>tr{
  background-color: #eee;
}
</style>
